import React from "react";
import styled from "styled-components";

const ContactStyle = styled.div`
    width: 100%;
    .contact-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 120px 0;
        h1 {
            font-weight: bold;
            font-size: 32px;
            span {
                font-weight: bold;
            }
            span.CRM {
                color: var(--primary-color);
            }
            span.UTS {
                color: var(--secondary-color);
            }
            span.EDU {
                color: var(--blue);
            }
        }
        h2 {
            color: var(--dark);
        }
        .wechat-ent-code {
            width: 100px;
            height: 100px;
            background: url("https://mtimg.onemiao.cn/web-images/wechat_qrcode.jpeg");
            background-size: 100px;
            margin: 40px 0 10px;
        }
        @media (max-width: 500px) {
            padding: 60px 20px;
            h1 {
                font-size: 24px;
            }
            .wechat-ent-code {
                margin: 20px 0 10px;
            }
        }
    }
`

export default function Contact({ product }) {
    return <ContactStyle>
        <div className="content-wrap">
            <div className="contact-info">
                <h1>想进一步了解苗通<span className={product}>{product}</span>？</h1>
                <h2>请联系我们的客户经理</h2>
                <div className="wechat-ent-code"></div>
                <p>扫一扫添加企业微信</p>
            </div>
        </div>
    </ContactStyle>
}