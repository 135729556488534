import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Contact from "../components/Contact";

const CRMHeroStyle = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #009B67 0%, #09E395 100%);
    border-bottom: 1px solid var(--primary-color);
    .CRMHero {
        width: 100%;
        max-width: 1240px;
        padding: 50px 20px 320px;
        display: flex;
        justify-content: center;
        background: url("https://mtimg.onemiao.cn/web-images/crm/crm_hero_bg.png") center bottom -20px no-repeat;
        background-size: contain;
    }
    .CRMHero-text {
        color: white;
        .title {
            font-size: 40px;
            span {
                color: #10FFAF;
                font-weight: bold;
                margin-left: 10px;
            }
        }
        h3 {
            margin-bottom: 24px;
        }
    }
    @media (max-width: 900px) {
        .CRMHero {
            padding: 50px 20px 220px;
        }
    }
    @media (max-width: 600px) {
        .content-wrap {
            padding: 0;
        }
        .CRMHero {
            padding-top: 30px;
            padding-bottom: 300px;
            background: url("https://mtimg.onemiao.cn/web-images/crm/crm_hero_bg_s.png") center bottom -20px no-repeat;
            background-size: contain;
        }
        .CRMHero-text {
            h3 {
                margin-bottom: 16px;
            }
        }
    }
    @media (max-width: 400px) {
        .CRMHero { padding-bottom: 200px; }
    }
`

export default function MiaotongCRM() {
    return <div>
        <CRMHeroStyle>
            <div className="content-wrap">
                <div className="CRMHero">
                    <div className="CRMHero-text">
                        <div className="title">苗通<span>CRM</span></div>
                        <h2>苗通客户关系综合管理系统</h2>
                        <h3>Miao Customer Relationship Management</h3>
                        <h2>帮助疫苗厂商及推广商<br />进行全业务链条的闭环管理</h2>
                    </div>
                </div>
            </div>
        </CRMHeroStyle>
        <div className="section-base-row">
            <h1>产品核心功能</h1>
            <div className="section-head crm1">
                <div className="section-info">
                    <div className="section-title">医院、苗源及接种信息管理功能</div>
                    <h2>疫苗推广行业产业链的闭环管理系统</h2>
                    <h3>为推广企业提供了医院拓展维护、苗源数据管理以及<br />可视化业务看板等功能，帮助企业沉淀数据资产。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>站点详情</span>
                            <span>接种管理</span>
                            <span>苗源管理</span>
                            <span>学术培训</span>
                        </div>
                        <div className="function-icon-row">
                            <span>业务报表</span>
                            <span>渠道分析</span>
                            <span>竞品分析</span>
                            <span>科普患教</span>
                        </div>
                    </div>
                </div>
                <StaticImage src="https://mtimg.onemiao.cn/web-images/crm/crm_function_graphic1.png" alt="" className="section-head-img" />
            </div>
        </div>
        <div className="section-cards-wrap gray crm1">
            <h2>产品特点</h2>
            <div className="card-group">
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func1_graphic1.svg" alt="" />
                    </div>
                    <h2>站点管理数据化</h2>
                    <h3>从完全依赖推广经理”个人能力“拓展维护，离职难交接，升级到基于数据分析的标准化拓展维护，团队化管理。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func1_graphic2.svg" alt="" />
                    </div>
                    <h2>苗源管理信息化</h2>
                    <h3>从针对苗源手动记录、分配、统计的低效管理，到建立苗源从推广到接种的全流程信息化闭环管理。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func1_graphic3.svg" alt="" />
                    </div>
                    <h2>业务报表可视化</h2>
                    <h3>从杂乱无章的各类报表数据手动汇总，到所有业务数据形成一站式、可视化的业务报表，关键数据一览无遗。</h3>
                </div>
            </div>
        </div>
        <div className="section-base-row">
            <div className="section-head crm2">
                <StaticImage src="https://mtimg.onemiao.cn/web-images/crm/crm_function_graphic2.png" alt="" className="section-head-img" />
                <div className="section-info">
                    <div className="section-title">智能推广渠道与用户管理</div>
                    <h2>疫苗推广关键渠道管理与用户运营系统</h2>
                    <h3>协助推广企业依托合规宣教内容与场景，利用数据化工具嫁接和管理高效推广渠道，并为终端用户提供良好的服务体验。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>渠道管理</span>
                            <span>回访记录</span>
                            <span>用户管理</span>
                            <span>预计操作</span>
                        </div>
                        <div className="function-icon-row">
                            <span>预约信息</span>
                            <span>接种预估</span>
                            <span>预约记录</span>
                            <span>进度报表</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-cards-wrap gray crm2">
            <h2>产品特点</h2>
            <div className="card-group">
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func2_graphic1.svg" alt="" />
                    </div>
                    <h2>一站式渠道管理</h2>
                    <h3>帮助推广企业一站式对接符合需求场景的用户宣教渠道，通过合规的内容与宣教形式触达更多用户，提升推广效果。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func2_graphic2.svg" alt="" />
                    </div>
                    <h2>数据化用户管理</h2>
                    <h3>推广企业的服务人员根据预约信息为用户提供优质服务，解决用户从预约到完成接种及接种后随访等环节的问题。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func2_graphic3.svg" alt="" />
                    </div>
                    <h2>可视化业务看板</h2>
                    <h3>通过可视化业务看板功能，推广企业可实时掌握推广业务动态、任务完成进度、推广经理工作计划执行等情况，一切尽在掌握。</h3>
                </div>
            </div>
        </div>
        <div className="section-base-row">
            <div className="section-head crm3">
                <div className="section-info">
                    <div className="section-title">推广经理日常工作效率管理</div>
                    <h2>协助企业提升推广经理的工作效率</h2>
                    <h3>苗通为疫苗推广企业提供推广经理日常的工作管理功能，包括考勤管理，科室会以及员工的学习任务等知识管理功能，极大的提高了员工的工作效率及合规意识。</h3>
                    <div className="function-group">
                        <h2>重点功能</h2>
                        <div className="function-icon-row">
                            <span>人员管理</span>
                            <span>培训管理</span>
                            <span>竞品管理</span>
                            <span>学习任务</span>
                        </div>
                        <div className="function-icon-row">
                            <span>考勤管理</span>
                            <span>录播直播</span>
                            <span>科室会</span>
                            <span>材料传递</span>
                        </div>
                    </div>
                </div>
                <StaticImage src="https://mtimg.onemiao.cn/web-images/crm/crm_function_graphic3.png" alt="" className="section-head-img" />
            </div>
        </div>
        <div className="section-cards-wrap gray crm2">
            <h2>产品特点</h2>
            <div className="card-group">
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func3_graphic1.svg" alt="" />
                    </div>
                    <h2>业务管理</h2>
                    <h3>推广经理可使用苗通产品记录关键信息及工作计划，推广工作形成数据化资产，并及时生成可视化业务分析报表，有助于业绩提升。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func3_graphic2.svg" alt="" />
                    </div>
                    <h2>学术交流</h2>
                    <h3>通过苗通的培训模块，推广经理可以通过多种形式与相关科室医生进行学术交流，有助于提高学术推广的便捷度和医生的接受度。</h3>
                </div>
                <div className="function-card">
                    <div className="function-graphic">
                        <img src="https://mtimg.onemiao.cn/web-images/crm/crm_func3_graphic3.svg" alt="" />
                    </div>
                    <h2>知识管理</h2>
                    <h3>苗通拥有持续更新的疫苗行业学习的知识库体系，企业可以自由组合学习内容，帮助自己的推广经理提高行业及业务水平。</h3>
                </div>
            </div>
        </div>
        <Contact product={"CRM"} />
    </div>
}